const OrderSubmission = () => {

    return (
        
        <div>
            <h1> Thank you for your order. We will be in touch soon to confirm your order details.</h1>
        </div>

    )


}


export default OrderSubmission;
