const ContactSubmission = () => {
    
    return (

        <div>
        <h1> Thank you for contacting us. We will respond as soon as possible</h1>
    </div>

    )

}


export default ContactSubmission;
